<template>
  <v-dialog v-model="dialog" max-width="1100" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Journal</span>
      </v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="journals" disable-pagination hide-default-footer>
          <template v-slot:top>
            <v-toolbar flat>
              -<v-row>
                <v-col cols="12" sm="12" md="6" lg="3">
                  <v-menu
                    v-model="menuDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        flat
                        dense
                        background-color="white"
                        v-model="date"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        label="Date"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date" @input="menuDate = false"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:item.no="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:item.debit="{ item }">
            {{ formatPrice(item.type === "Debit" ? item.amount : 0) }}
          </template>
          <template v-slot:item.credit="{ item }">
            {{ formatPrice(item.type === "Credit" ? item.amount : 0) }}
          </template>
          <template v-slot:body.append="{}">
            <tr>
              <td colspan="3">TOTAL</td>
              <td style="border: 1px">
                {{ formatPrice(debit) }}
              </td>
              <td style="border: 1px">
                {{ formatPrice(credit) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="close" class="mr-2">
          Close
        </v-btn>
        <v-btn color="primary" @click="save">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";

export default {
  name: "dialog-reverse",
  props: {
    dialog: Boolean,
    journals: Array,
  },
  data() {
    return {
      menuDate: false,
      date: moment().format("YYYY-MM-DD"),
      headers: [
        {
          id: 1,
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 2,
          text: "Account Number",
          value: "accountNumber",
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 2,
          text: "Account Name",
          value: "accountName",
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 3,
          text: "Debit",
          value: "debit",
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 3,
          text: "Credit",
          value: "credit",
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
    };
  },
  methods: {
    save() {
      this.$emit("save", { items: this.journals, date: this.date });
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  computed: {
    debit() {
      let total = 0;
      this.journals.forEach(x => {
        if (x.type === "Debit") {
          total += parseFloat(x.amount);
        }
      });
      return total;
    },
    credit() {
      let total = 0;
      this.journals.forEach(x => {
        if (x.type === "Credit") {
          total += parseFloat(x.amount);
        }
      });
      return total;
    },
  },
};
</script>

<style></style>
